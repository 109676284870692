import { Flex, Heading, Image, Link, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import "./navbar.css";

function Navbar() {
  const [isLargerThan1400] = useMediaQuery("(min-width: 1600px)");

  return (
    <>
      <Flex
        width={"100%"}
        paddingLeft={{ base: "1%", md: isLargerThan1400 ? "17%" : "9%" }}
        height={"fit-content"}
      >
        <Link href="https://bookinghero.io/">
          <Image
            src={process.env.PUBLIC_URL + "/logo_white.png"}
            objectFit={"contain"}
            alt="logo"
            width={"250px"}
            height={"fit-content"}
          />
        </Link>
      </Flex>
    </>
  );
}

export default Navbar;
