import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";

function Features() {
  const [isLargerThan1400] = useMediaQuery("(min-width: 1600px)");

  return (
    <>
      <Flex
        background={"white"}
        width={"100%"}
        height={{ base: "fit-content", md: isLargerThan1400 ? "15vh" : "20vh" }}
        style={{ boxShadow: "10px 17px 28px -17px rgba(0,0,0,0.20)" }}
        alignItems={"center"}
        justifyContent={"space-evenly"}
        gap={"50px"}
        paddingX={"40px"}
        flexDirection={{ base: "column", md: "row" }}
        paddingBottom={{ base: "5%", md: 0 }}
      >
        <Flex flex={1} gap={"15px"}>
          <Image
            src={process.env.PUBLIC_URL + "/icon1.png"}
            alt="icon1"
            objectFit={"contain"}
            width={"50px"}
            height={"50px"}
          />
          <Box>
            <Heading fontWeight={"700"} size={"md"} marginBottom={"8px"}>
              1. Vælg den bedste service
            </Heading>
            <Text fontWeight={"600"} fontSize={"15px"}>
              Vælg den service, der passer bedst til din kanal
            </Text>
          </Box>
        </Flex>
        <Flex flex={1} gap={"15px"}>
          <Image
            src={process.env.PUBLIC_URL + "/icon2.png"}
            alt="icon2"
            objectFit={"contain"}
            width={"50px"}
            height={"50px"}
          />
          <Box>
            <Heading fontWeight={"700"} size={"md"} marginBottom={"8px"}>
              2. Opret
            </Heading>
            <Text fontWeight={"600"} fontSize={"15px"}>
              Opret indhold og anbefal produkter til dit publikum
            </Text>
          </Box>
        </Flex>
        <Flex flex={1} gap={"15px"}>
          <Image
            src={process.env.PUBLIC_URL + "/icon3.png"}
            alt="icon2"
            objectFit={"contain"}
            width={"50px"}
            height={"50px"}
          />
          <Box>
            <Heading fontWeight={"700"} size={"md"} marginBottom={"8px"}>
              3. Tjen
            </Heading>
            <Text fontWeight={"600"} fontSize={"15px"}>
              Vores konkurrencedygtige provisionssats hjælper med at maksimere
              indtjeningen
            </Text>
          </Box>
        </Flex>
      </Flex>
    </>
  );
}

export default Features;
