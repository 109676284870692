import {
  Box,
  Flex,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Button,
  SimpleGrid,
  useBreakpointValue,
  IconProps,
  Icon,
  useToast,
  Image,
  Link,
  useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";

const Blur = (props: IconProps) => {
  return (
    <Icon
      width={useBreakpointValue({ base: "100%", md: "40vw", lg: "30vw" })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="71" cy="61" r="111" fill="#23A994" />
      <circle cx="244" cy="106" r="139" fill="#23A994" />
      <circle cy="291" r="139" fill="#23A994" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#23A994" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#23A994" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#23A994" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#23A994" />
    </Icon>
  );
};

export default function JoinOurTeam() {
  const toast = useToast();
  const [isLoading, setisLoading] = useState(false);
  const [inputs, setInputs] = useState({
    email: "",
    name: "",
    phone: "",
    social_platform: "",
    followers: "",
  });
  const handlechange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handle_submit = async (e) => {
    e.preventDefault();
    setisLoading(true);
    try {
      if (
        inputs.email === "" ||
        inputs.name === "" ||
        inputs.phone === "" ||
        inputs.social_platform === "" ||
        inputs.followers === ""
      ) {
        toast({
          title: "Skriv fejl",
          description: "E-mail- eller adgangskodefeltet er tomt",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
        setisLoading(false);
      } else {
        const Data = {
          email: inputs.email,
          name: inputs.name,
          phone: inputs.phone,
          social_media: inputs.social_platform,
          followers: inputs.followers,
        };

        const res = await axios.post("influencer/", Data);

        if (res.status === 200) {
          setisLoading(false);
          toast({
            title: "Indsendt",
            description: "Din ansøgning er indsendt !",
            position: "top-right",
            isClosable: true,
            status: "success",
          });
        }
      }
    } catch (err) {
      toast({
        title: "Server Fejl",
        description: "Prøv venligst igen om et øjeblik",
        position: "top-right",
        isClosable: true,
        status: "error",
      });

      setisLoading(false);
    }
  };

  const [isLowerThan1250] = useMediaQuery("(max-width: 1250px)");

  return (
    <Box position={"relative"} padding={0} margin={0}>
      <Container
        as={SimpleGrid}
        maxW={"100%"}
        columns={isLowerThan1250 ? { base: 1, md: 1 } : { base: 1, md: 2 }}
        minH={"100vh"}
        padding={0}
        margin={0}
      >
        <Stack
          backgroundImage={process.env.PUBLIC_URL + "/cover.jpg"}
          backgroundSize={"cover"}
          backgroundPosition={"top"}
          display={isLowerThan1250 ? "none" : "flex"}
        >
          <Link href="/">
            <Image
              src={process.env.PUBLIC_URL + "/logo_white.png"}
              objectFit={"contain"}
              alt="logo"
              width={"250px"}
              height={"fit-content"}
              marginLeft={"7%"}
              marginTop={"10px"}
            />
          </Link>
          <Heading
            lineHeight={1.1}
            fontSize={{ base: "3xl", sm: "4xl", md: "5xl", lg: "5xl" }}
            marginLeft={"8%"}
            marginTop={"10px"}
          >
            Velkommen til{" "}
            <Text
              as={"span"}
              bgGradient="linear(to-r, green.400,teal.400)"
              bgClip="text"
            >
              BOOKINGHERO
            </Text>{" "}
            Influencers Program
          </Heading>
          <Stack direction={"row"} spacing={4} align={"center"}></Stack>
        </Stack>
        <Stack
          bg={"white"}
          rounded={"xl"}
          paddingX={{ base: 4, sm: 6, md: 20 }}
          spacing={{ base: 8 }}
          alignSelf={"center"}
          width={isLowerThan1250 ? "100%" : "80%"}
        >
          <Stack spacing={4}>
            <Heading
              color={"gray.800"}
              lineHeight={1.1}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
            >
              Slut dig til vores team
              <Text
                as={"span"}
                bgGradient="linear(to-r, teal.400,green.400)"
                bgClip="text"
                marginLeft={"5px"}
              >
                !
              </Text>
            </Heading>
            <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
              Vi leder efter fantastiske indholdsskabere og influencers ligesom
              dig, til at slutte sig til vores team og vokse med fællesskabet og
              selvfølgelig og skyrocket din karriere og indkomst!
            </Text>
          </Stack>
          <Box as={"form"} mt={10}>
            <Stack spacing={4}>
              <Input
                name="name"
                required={true}
                placeholder="Navn"
                bg={"gray.100"}
                border={0}
                color={"gray.500"}
                _placeholder={{
                  color: "gray.500",
                }}
                borderRadius={0}
                onChange={handlechange}
              />
              <Input
                borderRadius={0}
                required={true}
                name="email"
                onChange={handlechange}
                placeholder="E-mail"
                bg={"gray.100"}
                border={0}
                color={"gray.500"}
                _placeholder={{
                  color: "gray.500",
                }}
              />
              <Input
                required={true}
                name="phone"
                borderRadius={0}
                onChange={handlechange}
                placeholder="Telefon"
                bg={"gray.100"}
                border={0}
                color={"gray.500"}
                _placeholder={{
                  color: "gray.500",
                }}
              />
              <Input
                required={true}
                borderRadius={0}
                name="social_platform"
                onChange={handlechange}
                placeholder="Social Platform"
                bg={"gray.100"}
                border={0}
                color={"gray.500"}
                _placeholder={{
                  color: "gray.500",
                }}
              />
              <Input
                required={true}
                borderRadius={0}
                name="followers"
                onChange={handlechange}
                placeholder="Antal Følgere"
                bg={"gray.100"}
                border={0}
                color={"gray.500"}
                _placeholder={{
                  color: "gray.500",
                }}
              />
            </Stack>
            {!isLoading ? (
              <>
                <Button
                  fontFamily={"heading"}
                  onClick={handle_submit}
                  mt={8}
                  borderRadius={0}
                  w={"full"}
                  bgGradient="linear(to-r, teal.400,green.400)"
                  color={"white"}
                  _hover={{
                    bgGradient: "linear(to-r, green.400,teal.400)",
                    boxShadow: "xl",
                  }}
                >
                  Tilmeldt
                </Button>
              </>
            ) : (
              <>
                <Button
                  fontFamily={"heading"}
                  isLoading
                  borderRadius={0}
                  loadingText="Indlæser"
                  mt={8}
                  w={"full"}
                  bgGradient="linear(to-r, teal.400,green.400)"
                  color={"white"}
                  _hover={{
                    bgGradient: "linear(to-r, green.400,teal.400)",
                    boxShadow: "xl",
                  }}
                >
                  Tilmeldt
                </Button>
              </>
            )}
          </Box>
          form
        </Stack>
      </Container>
    </Box>
  );
}
