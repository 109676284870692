import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import "./App.css";
import "./fonts/NexaBold.otf";
import "./fonts/NexaLight.otf";
import "./fonts/NexaRegular.otf";
import "./fonts/NexaHeavy.ttf";

import Navbar from "./components/Navbar";
import Features from "./components/Features";

function App() {
  const [isLargerThan1400] = useMediaQuery("(min-width: 1600px)");

  return (
    <>
      <Box>
        <Flex
          minH={isLargerThan1400 ? "50vh" : "60vh"}
          background={"#23A994"}
          position={"relative"}
          flexDirection={"column"}
        >
          <Navbar />
          <Flex
            paddingLeft={{ base: "0", md: isLargerThan1400 ? "18%" : "10%" }}
            marginTop={{ base: "70px", md: "15px" }}
            flexDirection={"column"}
          >
            <Heading
              size={"2xl"}
              lineHeight={1.1}
              color={"white"}
              width={{ base: "100%", md: "50%" }}
              maxWidth={"650px"}
              fontWeight={"600"}
              marginBottom={"20px"}
              textAlign={{ base: "center", md: "unset" }}
            >
              Tjen penge på dit indhold med Bookingheros Influencer Program
            </Heading>
            <Button
              as={"a"}
              href={"/sign-up"}
              width={"fit-content"}
              height={"max-content"}
              padding={"10px"}
              fontSize={"lg"}
              fontWeight={"600"}
              color={"#1e6d61"}
              style={{
                background: "linear-gradient(to right,#3cffe0 0%,#fff2ed 100%)",
                boxShadow: "0 4px 8px #837777",
              }}
              borderRadius={"0"}
              alignSelf={{ base: "center", md: "unset" }}
              _hover={{ boxShadow: "0 4px 8px 2px #0006" }}
            >
              Tilmeld dig nu
            </Button>
          </Flex>
          <Box
            width={"fit-content"}
            height={"fit-content"}
            position={"absolute"}
            bottom={"15%"}
            right={isLargerThan1400 ? "17%" : "15%"}
            zIndex={"2"}
          >
            <Image
              src={process.env.PUBLIC_URL + "/influencer3.png"}
              objectFit={"contain"}
              alt="influencer image"
              height={isLargerThan1400 ? "50vh" : "60vh"}
              display={{ base: "none", sm: "none", md: "flex" }}
            />
          </Box>
          <Box
            zIndex={"3"}
            alignSelf={"center"}
            justifySelf={"center"}
            position={"absolute"}
            display={{ base: "none", md: "initial" }}
            paddingLeft={isLargerThan1400 ? "18%" : "10%"}
            paddingRight={isLargerThan1400 ? "18%" : "10%"}
            width={"100%"}
            bottom={"-15%"}
          >
            <Features />
          </Box>
        </Flex>

        <Box
          zIndex={"3"}
          alignSelf={"center"}
          justifySelf={"center"}
          position={{ base: "relative", md: "absolute" }}
          marginTop={{ base: "50px", md: "0" }}
          paddingLeft={isLargerThan1400 ? "15%" : "10%"}
          paddingRight={isLargerThan1400 ? "15%" : "10%"}
          width={"100%"}
          bottom={"-15%"}
          display={{ md: "none", base: "initial" }}
        >
          <Features />
        </Box>

        <Box
          width={"100%"}
          paddingX={isLargerThan1400 ? "18%" : "10%"}
          marginTop={"100px"}
        >
          <Heading size={"lg"} marginBottom={"10px"}>
            Bookinghero Influencer Program
          </Heading>
          <Text textAlign={"justify"} fontWeight={"600"}>
            Som Bookinghero Influencer giver vi dig de værktøjer, du har brug
            for til at vælge de bedste af Bookingheros tjenester, nemt anbefale
            dem til dine følgere og tjene provision på kvalificerende køb. Ved
            at opleve vores services og tjenester personligt, kan du give dine
            følgere den mest autentiske deling og anbefaling.
          </Text>
        </Box>
        <Box
          width={"100%"}
          paddingX={isLargerThan1400 ? "18%" : "10%"}
          marginTop={"50px"}
        >
          <Heading size={"lg"} marginBottom={"10px"}>
            Ofte stillede spørgsmål
          </Heading>
          <Flex
            marginTop={"30px"}
            marginBottom={"30px"}
            width={"100%"}
            gap={"30px"}
            alignItems={"center"}
            justifyContent={"space-between"}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Box
              flex={"1"}
              background={"gray.100"}
              padding={"30px"}
              minHeight={isLargerThan1400 ? "fit-content" : "180px"}
            >
              <Heading size={"md"} marginBottom={"10px"}>
                Hvordan kan jeg tjene penge på mit indhold?
              </Heading>
              <Text textAlign={"justify"} fontWeight={"600"}>
                Der er flere måder, du kan tjene på. Du kan tjene penge på dit
                sociale medieindhold ved at dele dine tilknyttede links.
              </Text>
            </Box>
            <Box
              flex={"1"}
              background={"gray.100"}
              padding={"30px"}
              minHeight={isLargerThan1400 ? "fit-content" : "180px"}
            >
              <Heading size={"md"} marginBottom={"10px"}>
                Hvad er en Bookinghero Influencer?
              </Heading>
              <Text textAlign={"justify"} fontWeight={"600"}>
                Som medlem af Bookinghero Influencer Program er du en
                indholdsskaber, der giver anbefalinger til at inspirere dit
                publikum via sociale medier.
              </Text>
            </Box>
          </Flex>
          <Flex
            marginTop={"30px"}
            marginBottom={"30px"}
            width={"100%"}
            gap={"30px"}
            alignItems={"center"}
            justifyContent={"space-between"}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Box flex={"1"} background={"gray.100"} padding={"30px"}>
              <Heading size={"md"} marginBottom={"10px"}>
                Hvordan kvalificerer jeg mig til dette program?
              </Heading>
              <Text textAlign={"justify"} fontWeight={"600"}>
                Vi accepterer ansøgninger fra alle typer influencere, forudsat
                at du har en YouTube-, Instagram-, Facebook- eller Tictok-konto.
                Når vi gennemgår din ansøgning, ser vi på antallet af følgere,
                du har ud over andre engagementsmålinger.
              </Text>
            </Box>
            <Box flex={"1"} background={"gray.100"} padding={"30px"}>
              <Heading size={"md"} marginBottom={"10px"}>
                Hvor meget vil jeg tjene?
              </Heading>
              <Text textAlign={"justify"} fontWeight={"600"}>
                Du kan begynde at tjene kommission, når dine følgere foretager
                kvalificerende køb via dine tilknyttede links. Det beløb, du
                tjener, er baseret på de solgte produkter. Kommissionssatserne
                er op til 6 %, og vil være synlige i dine rapporter.
              </Text>
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
}

export default App;
