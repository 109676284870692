import React from "react";
import axios from "axios";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Signup from "./Signup.tsx";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
axios.defaults.baseURL = "https://api-v3.bookinghero.io/api/";
//axios.defaults.baseURL = "http://localhost:5000/api/";
axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider>
        <Routes>
          <Route exact path="/" element={<App />} />
          <Route exact path="/sign-up" element={<Signup />} />
        </Routes>
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>
);
